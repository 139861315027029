import React, { useEffect, useState } from "react";
import { useAuth } from "./auth/AuthContext"; // Adjust the path to point to your AuthContext file
import { Link } from "react-router-dom";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import "../assets/css/Footer.css";

function Footer() {
    //const { currentUser } = useAuth();
    const [isShowMobileFooterOption, setIsShowMobileFooterOption] = useState(false);

    const handleMobileFooter = () => {
        setIsShowMobileFooterOption((prevData) => !prevData);
    };

    const handleFooterMenuClick = () => {
        setIsShowMobileFooterOption(false);
    };

    useEffect(() => {
        setIsShowMobileFooterOption(false);
    }, []);

    return (
        <footer>
            <div className="container">
                <p>
                    <a href="https://www.facebook.com/rippaday">
                        <img
                            src={facebook}
                            height="25"
                            width="25"
                            alt="RippaDay"
                        />{" "}
                    </a>
                    <a href="https://twitter.com/rippaday">
                        <img
                            src={twitter}
                            height="24"
                            width="24"
                            alt="RippaDay"
                        />{" "}
                    </a>
                    <a href="https://www.instagram.com/rippaday">
                        <img
                            src={instagram}
                            height="25"
                            width="25"
                            alt="RippaDay"
                        />{" "}
                    </a>
                    | &copy; Rippaday.com <strong> </strong>
                    | <Link to="https://app.rippaday.com/Members">Login </Link>
                    | &nbsp;
                    <a href="/terms">
                        Terms of Use
                    </a>{" "}
                    |{" "}
                    <a href="/privacy">Privacy Policy</a>{" "}
                    |{" "}
                    <a href="/about">
                        What is Dutch Betting?{" "}
                    </a>
                    | All information is for entertainment purposes only. Gamble
                    Responsibly.{" "}
                    <a href="http://www.gamblinghelponline.org.au">
                        www.gamblinghelponline.org.au
                    </a>
                </p>
            </div>

            <div className="mobile-footer">
                <i className="fa fa-bars" onClick={handleMobileFooter}></i>
                <ul className={isShowMobileFooterOption ? "expanded" : ""}>
                    <a href="/terms">
                        <li onClick={handleFooterMenuClick}>Terms of Use</li>
                    </a>
                    <a href="/privacy">
                        <li onClick={handleFooterMenuClick}>Privacy Policy</li>
                    </a>
                    <a href="/dutch">
                        <li onClick={handleFooterMenuClick}>
                            What is Dutch Betting?
                        </li>
                    </a>
                </ul>
            </div>
        </footer>
    );
}

export default React.memo(Footer);
