//import logo from './logo.svg';
import "./App.css";
import "./assets/css/bootstrap.min.css";
//import './assets/css/font-awesome.min.css';
import "./assets/css/main.css";
import "./assets/css/rpd.css";
import { Amplify, Auth } from "aws-amplify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./components/auth/AuthContext";
import Header from "./components/Header";
import TOTD from "./components/Totd";
import Terms from "./components/Terms";
import Dutch from "./components/Dutch";
import Privacy from "./components/Privacy";
import Footer from "./components/Footer";
import Drink from "./components/Drink";

async function refreshToken() {
    // refresh the token here and get the new token info
    // ......
    // const userSession = getUserSession();
    // const token = userSession.idToken.jwtToken;
    // const expires_at = userSession.idToken.payload.exp * 1000; // Convert to milliseconds

    const session = await Auth.currentSession();

    const token = session.getIdToken().getJwtToken();
    const expires_at = session.getIdToken().getExpiration() * 1000; // Convert to milliseconds

    const identity_id = null;

    const data = {
        token, // the token from the provider
        expires_at, // the timestamp for the expiration
        identity_id, // optional, the identityId for the credentials
    };

    return data;
}

Amplify.configure({
    Auth: {
        region: "ap-southeast-2",
        userPoolId: "ap-southeast-2_Ar79EBArz",
        userPoolWebClientId: "1f7ph2vfcoandd21kkpgj3jbqp",
        // refreshHandlers: {
        //   'developer': refreshToken
        // }
    },
});

function App() {
    return (
        <AuthProvider>
            <Router basename="/">
                <Header />
                <Drink />
                <Routes>
                    <Route exact path="/" element={<TOTD />} />
                    <Route exact path="/Terms/" element={<Terms />} />
                    <Route exact path="/Privacy/" element={<Privacy />} />
                    <Route exact path="/Terms" element={<Terms />} />
                    <Route exact path="/Privacy" element={<Privacy />} />
                    <Route exact path="/Dutch" element={<Dutch />} />
                    <Route exact path="/About" element={<Dutch />} />
                    <Route exact path="/Dutch/" element={<Dutch />} />
                </Routes>
                <Footer />
            </Router>
        </AuthProvider>
    );
}

export default App;
