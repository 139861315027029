const apiUrl = process.env.REACT_APP_API_URL || "https://data.rippaday.com/v1";
const dataUrl = `${apiUrl}/today`;
const subscribeUrl = `${apiUrl}/subscribe`;
const subscriptionUrl = `${apiUrl}/subscription`;
const stripeToken =
    process.env.REACT_APP_STRIPE_TOKEN ||
    "pk_live_TAd2DXiZ8mYbqJGCdOH28flV00Mw2e8FdO";

const config = {
    apiUrl,
    dataUrl,
    subscribeUrl,
    subscriptionUrl,
    stripeToken,
};

export default config;
