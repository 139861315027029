import React from 'react';
import '../assets/css/Totd.css'; // Import the CSS file for styling

const Terms = () => {
  return (
    <div className="container">
      <section className="ctext section-body" id="header1-0" data-rv-view="0">
        <div className="filter-container">
        <div className="container">
          <div className="row">
            <div className="totdText">
              <p>Terms of use</p>
              <p>Information on this website is for entertainment purposes only. This information may not be used for any other purpose, saved or distributed without written prior consent.&nbsp;</p>
              <p>By using this website you are consenting to these terms of service. Your use of this website is subject to all applicable laws and regulations.</p>
              <p>All data displayed on this website is live and is subject to change without notice</p><br />
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  );
};

export default Terms;
